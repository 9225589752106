import "./index.css"

const Redes = () => {
  const Gmail = (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Gmail"
    role="img"
    viewBox="0 0 512 512"
    fill="#000000"
    height="50px"
    width="50px"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <rect width="512" height="512" rx="15%" fill="transparent"></rect>
      <path d="M158 391v-142l-82-63V361q0 30 30 30" fill="#4285f4"></path>
      <path
        d="M154 248l102 77l102-77v-98l-102 77l-102-77"
        fill="#ea4335"
      ></path>
      <path d="M354 391v-142l82-63V361q0 30-30 30" fill="#34a853"></path>
      <path
        d="M76 188l82 63v-98l-30-23c-27-21-52 0-52 26"
        fill="#c5221f"
      ></path>
      <path
        d="M436 188l-82 63v-98l30-23c27-21 52 0 52 26"
        fill="#fbbc04"
      ></path>
    </g>
  </svg>
    );  


    return (
        <div className="fixed-right">
        <div className="d-flex fixed_ align-items-end">
          <a target="_blank" rel="noopener noreferrer" className="mx-2" href="https://api.whatsapp.com/send?phone=541134675725&text=Hola!%20Me%20interesaria%20contactarme">
            <img className="whatsapp" src="/assets/img/redes/whatsapp.png" alt="whatsapp" />
          </a>
          <a target="_blank" rel="noopener noreferrer" className="mx-2" href="mailto:susanaespinoza.propiedades@gmail.com?subject=Contactarme&body=¡Buenas!%20Quisiera%20contactarme%20con%20ustedes%20por...">
              {Gmail}
          </a>
          <a target="_blank" rel="noopener noreferrer" className="mx-2" href=" https://www.instagram.com/susanaespinoza.propiedades/">
            <img className="whatsapp" src="/assets/img/redes/instagram.png" alt="whatsapp" />
         

          </a>
        </div>
      </div>
    )
}

export default Redes;