import { useEffect, useState } from "react";
import he from 'he'; // Importa la biblioteca 'he' para decodificar las descripciones

let BaseDeDatos = null;  // Variable para almacenar los datos
let ubicaciones = []; // Variable global para almacenar ubicaciones únicas

const fetchAndFormatData = async () => {
  try {
    const inm = 'SSE';
    const apiK = 'OXT0YCM6CC0CCMVUF44P58XTT';
    const url = 'https://xintel.com.ar/api/';

    const queryParams = {
      json: 'resultados.fichas',
      inm,
      apiK,
      tipo_operacion: '',
      tipo_inmueble: '',
    };

    const query = new URLSearchParams(queryParams).toString();
    const apiUrl = `${url}?${query}`;

    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.json();
    const fichas = result?.resultado?.fichas || [];

    const formattedData = fichas.map((property) => {
      const tipo = property.tipo || '';
      const ambientes = property.ambientes_num || '';
      const superficieTotal = property.in_sto || 'N/A';

      // Ajuste aquí para determinar la ubicación correctamente
      let ubicacion = property.in_bar !== "" ? property.in_bar : property.in_loc;

      // Almacenar ubicaciones únicas globalmente
      if (!ubicaciones.includes(ubicacion)) {
        ubicaciones.push(ubicacion);
      }

      const operacion = property.in_ope === 'V' ? 'VENTA' : 'ALQUILER';

      const metaDescripcion = `${tipo} en ${operacion} de ${ambientes} ambientes en ${ubicacion} con ${superficieTotal} m².`;

      return {
        nombre: property.titulo,
        imagen: property.img_princ,
        direccion_completa: property.direccion_completa,
        precio: property.precio,
        venta_precio: property.venta_precio,
        venta_moneda: property.venta_moneda,
        alquiler_precio: property.alquiler_precio,
        alquiler_moneda: property.alquiler_moneda,
        ambientes_2: property.ambientes,
        tipo: property.tipo,
        estado: property.estado,
        operacion: property.in_ope === 'V' ? 'ventas' : 'alquileres',
        ambientes: property.ambientes_num,
        dormitorios: property.dormitorios,
        id: property.inmueble_id,
        superficieTotal: property.in_sto,
        in_sul: property.in_sul,
        sup_semicubierta: property.sup_semicubierta,
        descripcion: property.in_obs,
        zonaCiudad: property.zonaCiudad,
        ubicacion,  // Aquí se asigna la ubicación determinada dinámicamente
        in_cubierto: property.in_cub,
        disponibilidad: property.in_esi,
        baños: property.in_bao,
        autos: property.in_coc,
        titulo: `${property.tipo} en ${property.in_ope === 'V' ? 'VENTA' : 'ALQUILER'} ${ubicacion}`,
        metaDescripcion
      };
    });

    return formattedData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};




const GetDatos = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ datos: [] });  // Inicializar con un objeto que tiene un array vacío

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!BaseDeDatos) {
          await fetchAndFormatUbi(); // Fetch ubicacion first
          const fetchedData = await fetchAndFormatData(); // Then fetch the main data
          BaseDeDatos = { datos: fetchedData };  // Store the main data in an object with a 'datos' property
        }

        setData(BaseDeDatos);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  
  return { loading, datos: data.datos };
};


const fetchAndFormatUbi = async () => {
  try {
    await fetchAndFormatData(); // Llamamos a fetchAndFormatData para asegurar que las ubicaciones estén actualizadas
    return ubicaciones.map((ubicacion) => ({
      descripcion: he.decode(ubicacion.trim())
    }));
  } catch (error) {
    console.error('Error al obtener las ubicaciones:', error);
    throw error;
  }
};



export { GetDatos, fetchAndFormatUbi };