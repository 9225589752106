// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { Helmet } from 'react-helmet-async';
// import "./css/Item.css";
// import { GetDatos } from "../json/Datos";
// import VuelveArriba from "../Componentes/vuelveArriba";

// const Item = () => {
//   const datos = GetDatos();
//   const baseDeDatos = datos.datos
//   const { fichaId } = useParams();
//   const url = `https://ficha.amaira.com.ar/nue/ficha.php?ficha=SSE${fichaId}`;

//   const [fetchedItem, setFetchedItem] = useState(null);
  
  
//   useEffect(() => {
//     const getItemById = (id) => {
//       if (!baseDeDatos) {
//         return null;
//       }
//       return baseDeDatos.find((item) => item.id === id) || null;
//     };

//     const item = getItemById(fichaId);
//     setFetchedItem(item);
//     if (item) {
//       document.title = `${item.titulo}`;
//     }
//   }, [fichaId, baseDeDatos]);

//   return (
//     <VuelveArriba>
//       <Helmet>
//         <title>{fetchedItem ? `Ficha - ${fetchedItem.titulo}` : "Cargando..."}</title>
//         <meta name="description" content={fetchedItem ? fetchedItem.metaDescripcion : "Cargando..."} />
//         <meta property="og:title" content={fetchedItem ? fetchedItem.titulo : "Cargando..."} />
//         <meta property="og:description" content={fetchedItem ? fetchedItem.metaDescripcion : "Cargando..."} />
//         <meta property="og:image" content={fetchedItem ? fetchedItem.imagen : "https://susanaespinoza.com.ar/favicon.ico"} />
//       </Helmet>
//       <div style={{ marginTop: '4rem' }}>
//         <iframe title="Contenido externo" className='contenedorInframe' src={url} frameBorder="0" />
//       </div>
//     </VuelveArriba>
//   );
// };

// export default Item;


import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import "./css/Item.css";
import { GetDatos } from "../json/Datos";
import VuelveArriba from "../Componentes/vuelveArriba";

const Item = () => {
  const datos = GetDatos();
  const baseDeDatos = datos.datos;
  const { fichaId } = useParams();
  const url = `https://ficha.amaira.com.ar/nue/ficha.php?ficha=SSE${fichaId}`;

  const [fetchedItem, setFetchedItem] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');
  const [error, setError] = useState(null);

  const iframeRef = useRef(null);

  useEffect(() => {
    const getItemById = (id) => {
      if (!baseDeDatos) {
        return null;
      }
      return baseDeDatos.find((item) => item.id === id) || null;
    };

    const item = getItemById(fichaId);
    setFetchedItem(item);
    if (item) {
      document.title = `${item.titulo}`;
    }
  }, [fichaId, baseDeDatos]);

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const html = await response.text();
        setHtmlContent(html);
      } catch (error) {
        setError(error);
        console.error('Error fetching HTML content:', error);
      }
    };

    fetchHtmlContent();
  }, [url]);

  useEffect(() => {
    const adjustIframeHeight = () => {
      if (iframeRef.current) {
        const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
        const iframeHeight = iframeDocument.documentElement.scrollHeight || iframeDocument.body.scrollHeight;
        iframeRef.current.style.height = `${iframeHeight}px`;
      }
    };

    if (htmlContent && iframeRef.current) {
      const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      iframeDocument.open();
      iframeDocument.write(htmlContent);
      iframeDocument.close();

      iframeRef.current.onload = () => {
        adjustIframeHeight();
        setInterval(adjustIframeHeight, 1000); // Adjust the iframe height every second
      };
    }
  }, [htmlContent]);

  return (
    <VuelveArriba>
      <Helmet>
        <title>{fetchedItem ? `Ficha - ${fetchedItem.titulo}` : "Cargando..."}</title>
        <meta name="description" content={fetchedItem ? fetchedItem.metaDescripcion : "Cargando..."} />
        <meta property="og:title" content={fetchedItem ? fetchedItem.titulo : "Cargando..."} />
        <meta property="og:description" content={fetchedItem ? fetchedItem.metaDescripcion : "Cargando..."} />
        <meta property="og:image" content={fetchedItem ? fetchedItem.imagen : "https://susanaespinoza.com.ar/favicon.ico"} />
      </Helmet>
      <div style={{ marginTop: '6rem' }}>
        {error ? (
          <div>Error: {error.message}</div>
        ) : (
          <iframe
            title="Contenido externo"
            ref={iframeRef}
            className="contenedorInframe"
            frameBorder="0"
            style={{ width: '100%' }}
          />
        )}
      </div>
    </VuelveArriba>
  );
};

export default Item;
