import { useEffect } from 'react';

const VuelveArriba = ({ children }) => {
  useEffect(() => {
    // Desplaza la página al principio cuando el componente se monta
    window.scrollTo(0, 0);

    // También puedes suscribirte al evento 'beforeunload' para desplazar la página al principio antes de salir
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Asegúrate de eliminar el evento 'beforeunload' al desmontar el componente
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); // Se ejecuta solo en el montaje inicial

  return <div>{children}</div>;
};

export default VuelveArriba;
