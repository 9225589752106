import React from "react";
import { Helmet } from 'react-helmet-async';
import MySwiperComponent from "../Componentes/carruselProductos";
import FiltroHome from "../Componentes/filtroHome";
import "./css/Home.css";
import { GetDatos } from "../json/Datos";
import { useNavigate } from "react-router-dom";
import VuelveArriba from "../Componentes/vuelveArriba";

const Home = () => {
  const { loading, datos } = GetDatos();  // Extrae 'datos' directamente
  const navigate = useNavigate();

  document.title = "Susana Espinoza - PROPIEDADES INMOBILIARIAS";

  const handleClick = () => {
    navigate("/tasaciones");
  };
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <Helmet>
        <title>Susana Espinoza - Home</title>
        <meta name="description" content="Susana Espinoza - Inmobiliaria - Bella Vista - San miguel - Muñiz" />
        <meta property="og:title" content="Susana Espinoza - Propiedades" />
        <meta property="og:description" content="Busca tu propiedad ideal. ¡Haz realidad tu sueño!" />
        <meta property="og:image" content="https://susanaespinoza.com.ar/favicon.ico" />
      </Helmet>
      <VuelveArriba>
        <section className="inicio">
          <img className="inicio-img" src="./assets/img/inicio - copia.jpg" alt="Imagen de inicio" />
          <div className="home-text-container">
            <h1 className="inicio-titulo"><strong>Tu hogar, nuestro compromiso</strong></h1>
            <h3 className="inicio-parrafo"><strong>Encuentra la casa de tus sueños con nosotros</strong></h3>
            <FiltroHome />
          </div>
        </section>

        <div className="destacado">
          {datos && Array.isArray(datos) && <MySwiperComponent slides={datos} />} {/* Asegúrate de que 'datos' es un array */}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: "3rem" }}>
          <div style={{ backgroundColor: "#9D202D", display: 'flex', flexDirection: 'column', alignItems: 'center', padding: "5rem", borderRadius: "50%", boxShadow: "0px 0px 6px 17px rgba(0, 0, 0, 0.2)", color: "white" }}>
            <h2 style={{ textAlign: "center" }}><strong>Tasamos Tu Propiedad</strong></h2>
            <button className="btn btn-dark" onClick={handleClick}>Tasar Mi Propiedad</button>
          </div>
        </div>
      </VuelveArriba>
    </div>
  );
};

export default Home;
