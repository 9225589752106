import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import Envio from './Envio';
import Redes from '../Redes';

const FormContacto = () => {
  const [enviado, setEnviado] = useState(false);
  
  
  const formSubmit = (event) => {
    event.preventDefault();
    const res = Envio(event)
    setEnviado(res)
  };
  const closeModal = () => {
    setEnviado(false);
    document.getElementById('FormContacto').reset(); // Reemplaza 'tuFormulario' con el ID de tu formulario
  };
  return (
    <div className='contact-all '> 
      {enviado && (
                    <div  className='overlay1'>
                      <div className='modal1'>
                        <div className="modal-header">
                          <span className="close" onClick={closeModal}>
                            &times;
                          </span>
                        </div>
                        <div className="modal-body">Se envió correctamente</div>
                      </div>
                    </div>
                  )}
      <div className="h-100 d-flex flex-column justify-content-center align-items-center">
              
        <div className="card card-form mt-5 p-4">
          <div className="card-body">
            <h1 className="text-center">Contáctenos</h1>
            <form onSubmit={formSubmit} id='FormContacto'>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="form_name">Nombre *</label>
                    <input
                      id="form_name"
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Por favor, ingresa tu nombre"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="form_lastname">Apellido *</label>
                    <input
                      id="form_lastname"
                      type="text"
                      name="surname"
                      className="form-control"
                      placeholder="Por favor, ingresa tu apellido"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="form_email">Email *</label>
                    <input
                      id="form_email"
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Por favor, ingresa tu email"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="form_number">Teléfono *</label>
                    <input
                      id="form_number"
                      type="tel"
                      name="phone"
                      className="form-control"
                      placeholder="Por favor, ingresa tu número de celular"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="form_message">Mensaje *</label>
                <textarea
                  id="form_message"
                  name="message"
                  className="form-control"
                  placeholder="Escribe tu mensaje aquí."
                  rows="4"
                  required
                ></textarea>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center mt-3">
                  <button type="submit" className="btn btn-bordo btn-send pt-2">
                    Enviar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        </div>
        <Redes/>
      </div>
  );
};

export default FormContacto;

