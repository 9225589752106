import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './Page/Home';
import Propiedades from './Page/Propiedades';
import Item from './Page/Item';
import Contacto from './Page/Contacto';
import Navegador from './Componentes/navbar';
import Foot from './Componentes/Foot';
import Tasaciones from './Page/Tasaciones';
import VuelveArriba from './Componentes/vuelveArriba';

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Navegador />
        <VuelveArriba>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/propiedades" element={<Propiedades />} />
              <Route path="/propiedades/:categoriaId" element={<Propiedades />} />
              <Route path="/propiedades/:categoriaId/:busqueda" element={<Propiedades />} />
              <Route path="/item/:fichaId" element={<Item />} />
              <Route path="/contacto" element={<Contacto />} />
              <Route path="/tasaciones" element={<Tasaciones />} />
            </Routes>
          </Router>
        </VuelveArriba>
        <Foot />
      </div>
    </HelmetProvider>
  );
}

export default App;
