import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import FiltroHome2 from "../Componentes/filtroHome/index2";
import ItemListContainer from "../Componentes/ItemListContainer";
import "./css/Propiedades.css";
import VuelveArriba from "../Componentes/vuelveArriba";

const Propiedades = () => {
  const [resultadosFiltro, setResultadosFiltro] = useState([]);

  const recibirResultadosDelFiltro = (resultados) => {
    setResultadosFiltro(resultados);
  };

  useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'publicación de inmuebles propiedades alquiler o venta casas departamentos');
    }
  }, []);

  document.title = "Propiedades - INMOBILIARIA SUSANA ESPINOZA";
  return (
    <div>
      <Helmet>
        <title>Propiedades - INMOBILIARIA SUSANA ESPINOZA</title>
        <meta name="description" content="Publicación de inmuebles propiedades alquiler o venta casas departamentos" />
        <meta property="og:title" content="Propiedades - INMOBILIARIA SUSANA ESPINOZA" />
        <meta property="og:description" content="Encuentra las mejores propiedades en alquiler y venta en Bella Vista, San Miguel, Muñiz y alrededores." />
        <meta property="og:image" content="https://susanaespinoza.com.ar/favicon.ico" />
      </Helmet>
      <VuelveArriba>
        <div>
          <FiltroHome2 enviarResultados={recibirResultadosDelFiltro} />
        </div>
        <div className="propiedades container">
          <ItemListContainer productosFiltrados={resultadosFiltro} />
        </div>
      </VuelveArriba>
    </div>
  );
};

export default Propiedades;
