
const Envio = (event) => {
    const formData = new FormData(event.target);
    
    return fetch("https://formsubmit.co/ajax/susanaespinoza.propiedades@gmail.com", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Error en la solicitud");
        }
        // Devuelve true si la respuesta es exitosa (cualquier código de respuesta 2xx)
        return true;
      })
      .catch((error) => {
        console.error("Error al enviar el formulario:", error);
        // Devuelve false en caso de error
        return false;
      });
  };
  
  export default Envio;
  
