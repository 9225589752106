import "./index.css"
const Foot = () => {
  const Gmail = (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Gmail"
    role="img"
    viewBox="0 0 512 512"
    fill="#000000"
    height="50px"
    width="50px"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <rect width="512" height="512" rx="15%" fill="transparent"></rect>
      <path d="M158 391v-142l-82-63V361q0 30 30 30" fill="#4285f4"></path>
      <path
        d="M154 248l102 77l102-77v-98l-102 77l-102-77"
        fill="#ea4335"
      ></path>
      <path d="M354 391v-142l82-63V361q0 30-30 30" fill="#34a853"></path>
      <path
        d="M76 188l82 63v-98l-30-23c-27-21-52 0-52 26"
        fill="#c5221f"
      ></path>
      <path
        d="M436 188l-82 63v-98l30-23c27-21 52 0 52 26"
        fill="#fbbc04"
      ></path>
    </g>
  </svg>
    );  
  const footerStyle = {
    backgroundColor: "black",
    minHeight: "100px",
    color: "white",
    padding: "1rem",
    justifyContent: "space-around", 
    alignItems: "center", // Centra verticalmente
  };

  const sectionStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "3rem",
    marginBottom: "3rem"
  };

  const logoStyle = {
    marginTop: "3%",
    height: "8rem",
  };

  const sectionIzquierda = {
    ...sectionStyle, 
    marginLeft: window.innerWidth > 992 ? "4rem" : null
  }
  const Astyles = {
    textDecoration: "none",
    color: "white"
  }
  const imgStyle = {
    with: "30px",
    height: "30px"
  }
  return (
    <div style={footerStyle} className="footer-container">
      {/* Sección izquierda */}
      <div className="footer-section" style={sectionIzquierda}>
        <img src="/LogoBlanco.png" alt="Logo" style={logoStyle} />
        <a target="_blank" rel="noopener noreferrer" style={Astyles} href="https://api.whatsapp.com/send?phone=541134675725&text=Hola!%20Me%20interesaria%20contactarme">
          <h6 style={{ marginTop: "2rem" }}>
          <img style={imgStyle} src="/assets/img/redes/whatsapp.png" alt="whatsapp" />
            1134675725
          </h6>
        </a>
        <a target="_blank" rel="noopener noreferrer" style={Astyles} href="mailto:susanaespinoza.propiedades@gmail.com?subject=Contactarme&body=¡Buenas!%20Quisiera%20contactarme%20con%20ustedes%20por...">
          <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
            <span style={{ marginRight: "0.5rem" }}>{Gmail}</span>
            <h6 style={{ margin: 0 }}>susanaespinoza.propiedades@gmail.com</h6>
          </div>
        </a>
        <a target="_blank" rel="noopener noreferrer" style={Astyles} href=" https://www.instagram.com/susanaespinoza.propiedades/">
          <h6 style={{ marginTop: "2rem" }}>
          <img style={imgStyle} src="/assets/img/redes/instagram.png" alt="whatsapp" />
            susanaespinoza.propiedades
          </h6>
        </a>
      </div>

      { }
      <div className="footer-section" style={sectionStyle}>
        <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Juan+José+Paso+1711,+B1662CYJ+Muñiz,+Provincia+de+Buenos+Aires/@-34.5536945,-58.705192,18z/data=!4m6!3m5!1s0x95bcbda6e2184d7d:0xb5a996ec43cebe72!8m2!3d-34.5533133!4d-58.7069513!16s%2Fg%2F11t3c4062k?hl=es-419&gl=AR" style={{textDecoration: "none" , color: "white"}}>
          <p>Dirección: Juan José Paso 1711, Muñiz</p>
        </a>
        <iframe
        title="Susana Espinoza Propiedades"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d821.5023903459928!2d-58.70759637148952!3d-34.55331329830372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcbda6e2184d7d%3A0xb5a996ec43cebe72!2sJuan%20Jos%C3%A9%20Paso%201711%2C%20B1662CYJ%20Mu%C3%B1iz%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1695938489773!5m2!1ses-419!2sar"
            width="400rem"
            height="300rem"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>

      </div>


    </div>
  );
};

export default Foot;
