import React from 'react';
import { Helmet } from 'react-helmet-async';
import FormTasaciones from "../Componentes/formulario/tasaciones";
import VuelveArriba from '../Componentes/vuelveArriba';

const Tasaciones = () => {
  const pageTitle = "Tasaciones - INMOBILIARIA SUSANA ESPINOZA";
  const pageDescription = "Solicita una tasación con INMOBILIARIA SUSANA ESPINOZA para obtener una evaluación precisa de tu propiedad. Estamos aquí para brindarte un servicio profesional y confiable.";

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content="https://susanaespinoza.com.ar/favicon.ico" />
      </Helmet>
      <VuelveArriba>
        <FormTasaciones />
      </VuelveArriba>
    </div>
  );
};

export default Tasaciones;
