import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../node_modules/swiper/swiper.css';
import ProductsDestacados from './productos';
import { Link } from 'react-router-dom';

const MySwiperComponent = ({ slides }) => {
  const linkStyle = {
    position: 'relative',
    marginTop: '15px',
    marginLeft: '90%',
    marginBottom: '-10px'
  };

  let slidesPerView = 4; // Número predeterminado de slides por vista

  // Media query para cambiar la cantidad de slides por vista en dispositivos pequeños
  if (window.innerWidth < 768) {
    linkStyle.marginLeft = '80%';
    slidesPerView = 1.2;
  }
  else if (window.innerWidth > 768 && window.innerWidth < 1100) {
    slidesPerView = 2;
  }
  else if (window.innerWidth > 1100 && window.innerWidth < 1500) {
    slidesPerView = 3;
  }
  else if (window.innerWidth > 1500) {
    slidesPerView = 4;
  }

  if (!Array.isArray(slides)) {
    console.error('slides is not an array:', slides);
    return null;
  }

  return (
    <div>
      <div style={linkStyle}>
        <Link to={'/Propiedades'} className='btn'>
          Ver Todos
        </Link>
      </div>
      <Swiper
        spaceBetween={50}
        slidesPerView={slidesPerView}
        style={{ paddingLeft: "4rem" }}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <div className='borde shadow mb-6 mt-4'>
              <ProductsDestacados {...slide} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MySwiperComponent;
