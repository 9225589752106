import React, { useState, useEffect } from "react";
import ItemList from "../ItemList/index";
import { useParams } from "react-router-dom";
import { GetDatos } from "../../json/Datos";
const filtrarPorCategorias = (productos, categoria, filtros) => {
  let productosFiltrados = productos;

  // Filtrar por inputValue si está definido y no es una cadena vacía
  if (filtros.inputValue && filtros.inputValue !== '') {
    productosFiltrados = productosFiltrados.filter((producto) => {
      const values = Object.values(producto).map(value => String(value).toLowerCase());
      const searchTerm = filtros.inputValue.toLowerCase();
      return values.some(value => value.includes(searchTerm));
    });
  }

  // Filtrar por categoriaId
  productosFiltrados = productosFiltrados.filter((producto) => {
    const ubicacionSeleccionada = filtros.ubicacionSeleccionada || "cualquiera";
    const tipoPropiedadSeleccionada = filtros.tipoPropiedadSeleccionada || "cualquiera";
    const categoriaSeleccionada = filtros.operacionSeleccionada || "cualquiera";

    const categoriaMatch =
      categoriaSeleccionada === "cualquiera" || (producto.operacion && producto.operacion.toLowerCase() === categoriaSeleccionada.toLowerCase());
    const ubicacionMatch =
      ubicacionSeleccionada.toLowerCase() === "cualquiera" || (producto.ubicacion && producto.ubicacion.toLowerCase() === ubicacionSeleccionada.toLowerCase());
    const tipoPropiedadMatch =
      tipoPropiedadSeleccionada.toLowerCase() === "cualquiera" || (producto.tipo && producto.tipo.toLowerCase() === tipoPropiedadSeleccionada.toLowerCase());

    return categoriaMatch && ubicacionMatch && tipoPropiedadMatch;
  });

  return productosFiltrados;
};

const ItemListContainer = (props) => {
  const [productos, setProductos] = useState([]);
  const { categoriaId } = useParams();
  const datos = GetDatos();
  const baseDeDatos = datos.datos
  
  useEffect(() => {
    if (baseDeDatos) {
      let productosFiltrados = filtrarPorCategorias(baseDeDatos, categoriaId, props.productosFiltrados);
      setProductos(productosFiltrados || []);
    }
  }, [categoriaId, baseDeDatos, props.productosFiltrados]);

  return (
    <div>
      <ItemList productos={productos} />
    </div>
  );
};

export default ItemListContainer;