import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./index.css"
const FiltroHome = () => {
  const [filtroSeleccionado, setFiltroSeleccionado] = useState('alquileres');
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();

  const handleFiltroClick = (filtro) => {
    setFiltroSeleccionado(filtro);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleBuscarClick = () => {
    // Construir la URL con el filtro y el valor del input
    const url = `/propiedades/${filtroSeleccionado}/${encodeURIComponent(inputValue)}`;
    navigate(url);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleBuscarClick(); // Ejecutar búsqueda si se presiona Enter
    }
  };

  return (
    <div className="container mt-4">
      <div className="row justify-content-start">
        <div className="col-auto">
          <button
            className={`filtro-btn btn ${filtroSeleccionado === 'alquileres' ? 'btn-dark' : 'btn-bordo'} mb-2 m-1`}
            onClick={() => handleFiltroClick('alquileres')}
          >
            Alquiler
          </button>
          <button
            className={`filtro-btn btn ${filtroSeleccionado === 'ventas' ? 'btn-dark' : 'btn-bordo'} mb-2 m-1`}
            onClick={() => handleFiltroClick('ventas')}
          >
            Venta
          </button>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Buscar..."
              onKeyPress={handleKeyPress} // Manejar Enter key press
            />
            <button
              className="btn btn-dark"
              type="button"
              onClick={handleBuscarClick}
            >
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltroHome;
