import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import Redes from '../Redes';
import Envio from './Envio';

const FormTasaciones = () => {
    const [enviado, setEnviado] = useState(false);
  
  
    const formSubmit = (event) => {
        event.preventDefault();
        const res = Envio(event)
        setEnviado(res)
  
    };
    const closeModal = () => {
      setEnviado(false);
      document.getElementById('FormContacto').reset(); // Reemplaza 'tuFormulario' con el ID de tu formulario
    };
  

  return (
    <div className='tasaciones-all'>
            {enviado && (
                    <div  className='overlay1'>
                      <div className='modal1 in-line'>
                        <div className="modal-header">
                          <span className="close" onClick={closeModal}>
                            &times;
                          </span>
                        </div>
                        <div className="modal-body">En Breve nos estaremos contactando</div>
                      </div>
                    </div>
                  )}    
    <div className=" h-100 d-flex flex-column justify-content-center align-items-center">    
    <div className="card card-form mt-5 p-4">
        <div className="card-body">
            <h1 className="text-center">TASAMOS SU PROPIEDAD</h1>
            <form onSubmit={formSubmit}>
            <div className="row">
                <div className="col-md-6">
                <div className="form-group m-2">
                    <label htmlFor="form_name">Nombre y Apellido *</label>
                    <input
                    id="form_name"
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Por favor, ingresa tu nombre y apellido *"
                    required
                    />
                </div>
                </div>
                <div className="col-md-6 ">
                <div className="form-group m-2">
                    <label htmlFor="form_email">Email *</label>
                    <input
                    id="form_email"
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Por favor, ingresa tu email *"
                    required
                    />
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                <div className="form-group m-2">
                    <label htmlFor="form_number">Teléfono *</label>
                    <input
                    id="form_number"
                    type="tel"
                    name="phone"
                    className="form-control"
                    placeholder="Por favor, ingresa tu número de celular *"
                    required
                    />
                </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group m-2">
                        <label htmlFor="property_operation">Operación</label>
                        <input
                        id="property_operation"
                        type="text"
                        name="property_operation"
                        className="form-control"
                        placeholder="Operación de la propiedad"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center mt-3">
                <button type="submit" className="btn btn-bordo btn-send pt-2">
                    Enviar
                </button>
                </div>
            </div>
            </form>
        </div>
        </div>
    </div>
      <Redes/>
    </div>
  );
  
};

export default FormTasaciones;

