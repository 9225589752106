import "./index.css";
import React, { useState } from 'react';

const Navegador = () => {
  const [scrolling, setScrolling] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Función para manejar el desplazamiento de la página
  const handleScroll = () => {
    if (window.scrollY > 60) {
      setScrolling(true); // Cambia el estado a true cuando se desplaza hacia abajo
    } else {
      setScrolling(false); // Cambia el estado a false cuando se encuentra en la parte superior
    }
  };

  // Función para manejar la apertura y cierre del menú desplegable
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setScrolling(!isDropdownOpen); // Cambia el estado de scrolling cuando se abre o cierra el menú
  };

  // Agregar un evento de escucha para el desplazamiento
  window.addEventListener('scroll', handleScroll);

  return (
    <nav className={`navbar navbar-expand-lg navbar-light ${scrolling ? 'bg-dark' : 'bg-transparent'}`}>
      <div className="container-fluid">
        <a className={`navbar-brand ${scrolling ? 'text-white' : 'text-black'}`} href="/">
          <div className="d-flex align-items-center">
            <img className="logo" src={`${scrolling ? '/LogoBlanco.png' :  '/LogoNegro.png'}`} alt="Susana Espinoza" />
            <div className="ms-3 d-flex flex-column text-center ">
              <h5 className="m-0 letra">SUSANA ESPINOZA</h5>
              <hr className= {` ${scrolling ?  'wlinea' : 'linea' }`} />
              <h6 className="m-0 mt-1 letra">PROPIEDADES</h6>
            </div>

          </div>
        </a>


        <button
          className={`navbar-toggler ${isDropdownOpen ? 'bg-dark' : ''}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleDropdownToggle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse justify-content-center ${isDropdownOpen ? 'bg-dark' : ''}`} id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className={`nav-link ${scrolling ? 'text-white' : 'text-black'}`} href="/"><strong>Home</strong></a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${scrolling ? 'text-white' : 'text-black'}`} href="/Propiedades/ventas"><strong>Ventas</strong></a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${scrolling ? 'text-white' : 'text-black'}`} href="/Propiedades/alquileres"><strong>Alquileres</strong></a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${scrolling ? 'text-white' : 'text-black'}`} href="/Contacto"><strong>Contacto</strong></a>
            </li>
          </ul>
        </div>
      
        <div className="borrar d-flex align-items-center">
            <img className="logo" src={`${scrolling ? '/LogoBlanco.png' :  '/LogoNegro.png'}`} alt="Susana Espinoza" />
            <div className="ms-3 d-flex flex-column text-center ">
              <h5 className="m-0 letra">SUSANA ESPINOZA</h5>
              <hr className= {` ${scrolling ?  'wlinea' : 'linea' }`} />
              <h6 className="m-0 mt-1 letra">PROPIEDADES</h6>
            </div>

          </div>
      </div>
    </nav>
  );
}

export default Navegador;
