import React, { useEffect, useState, useCallback } from "react";
import './Filtro2.css';
import { useNavigate, useParams } from "react-router-dom";
import { fetchAndFormatUbi } from "../../json/Datos";

const FiltroHome2 = (props) => {
  const navigate = useNavigate();
  const { categoriaId, busqueda } = useParams();

  const [ubicaciones, setUbicaciones] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAndFormatUbi();
        setUbicaciones(data);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener las ubicaciones:', error);
      }
    };

    fetchData();
  }, []);

  const [filtros, setFiltros] = useState({
    ubicacionSeleccionada: 'cualquiera',
    operacionSeleccionada: categoriaId,
    tipoPropiedadSeleccionada: 'cualquiera',
    inputValue: '', // Inicializado con un valor predeterminado vacío
  });

  const enviarResultados = useCallback((resultados) => {
    props.enviarResultados(resultados);  // Llama a la función para pasar los resultados al componente padre (Propiedades)
    let ruta;
    if (resultados.operacionSeleccionada === 'cualquiera') {
      ruta = '/propiedades';
    } else if (resultados.operacionSeleccionada === "Ventas") {
      ruta = '/propiedades/ventas';
    } else if (resultados.operacionSeleccionada === "Alquileres") {
      ruta = '/propiedades/alquileres';
    } else {
      ruta = '/propiedades';
    }

    navigate(ruta);
  }, [props, navigate]);

  useEffect(() => {
    const operacionFromURL = categoriaId === "ventas" ? "Ventas" : categoriaId === "alquileres" ? "Alquileres" : "cualquiera";
    const buscador = busqueda !== undefined ? busqueda : '';
    setFiltros(prevFiltros => ({
      ...prevFiltros,
      inputValue: buscador,
      operacionSeleccionada: operacionFromURL
    }));
  }, [categoriaId, busqueda]);

  const aplicarFiltro = () => {
    enviarResultados(filtros);
  };

  const handleUbicacionChange = (event) => {
    setFiltros({ ...filtros, ubicacionSeleccionada: event.target.value });
  };

  const handleOperacionChange = (event) => {
    setFiltros({ ...filtros, operacionSeleccionada: event.target.value });
  };

  const handleTipoPropiedadChange = (event) => {
    setFiltros({ ...filtros, tipoPropiedadSeleccionada: event.target.value });
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value || ''; // Valor predeterminado vacío si es undefined
    setFiltros({ ...filtros, inputValue });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      aplicarFiltro();
    }
  };

  useEffect(() => {
    // Se ejecuta la primera vez que se monta el componente
    enviarResultados(filtros);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // [] indica que este useEffect solo se ejecutará una vez al montar el componente

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="filtro-container">
      <div className="mt-4">
        <div className="row justify-content-center">
          <div className="col">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={filtros.inputValue}
                onChange={handleInputChange}
                placeholder="Buscar..."
                onKeyPress={handleKeyPress}
              />
              <button className="btn btn-bordo" type="button" onClick={aplicarFiltro}>Buscar</button>
            </div>
          </div>
        </div>
        <div className="row justify-content-start" style={{ color: "white" }}>
          <div className="col-auto mt-2">
            <label htmlFor="operacion"><strong>TIPO DE OPERACION</strong></label>
            <select
              id="operacion"
              className="form-select"
              value={filtros.operacionSeleccionada}
              onChange={handleOperacionChange}
            >
              <option value="cualquiera">Cualquiera</option>
              <option value="Alquileres">Alquiler</option>
              <option value="Ventas">Venta</option>
            </select>
          </div>
          <div className="col-auto mt-2">
            <label htmlFor="ubicacion"><strong>UBICACION</strong></label>
            <select
              id="ubicacion"
              className="form-select"
              value={filtros.ubicacionSeleccionada}
              onChange={handleUbicacionChange}
            >
              <option value="cualquiera">Cualquiera</option>
              {ubicaciones.map((ubi, index) => (
                <option key={index} value={ubi.descripcion}>{ubi.descripcion}</option>
              ))}
            </select>
          </div>
          <div className="col-auto mt-2">
            <label htmlFor="tipoPropiedad"><strong>TIPO DE PROPIEDAD</strong></label>
            <select
              id="tipoPropiedad"
              className="form-select"
              value={filtros.tipoPropiedadSeleccionada}
              onChange={handleTipoPropiedadChange}
            >
              <option value="cualquiera">Cualquiera</option>
              <option value="Casa">Casa</option>
              <option value="Departamento">Departamento</option>
              <option value="Duplex">Duplex</option>
              <option value="Terreno">Terreno</option>
              <option value="Local">Local</option>
              <option value="Oficina">Oficina</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltroHome2;
