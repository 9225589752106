import React from 'react';
import { Helmet } from 'react-helmet-async';
import FormContacto from '../Componentes/formulario';
import VuelveArriba from '../Componentes/vuelveArriba';

const Contacto = () => {
  const pageTitle = "Contacto - INMOBILIARIA SUSANA ESPINOZA";
  const pageDescription = "Ponte en contacto con INMOBILIARIA SUSANA ESPINOZA para obtener más información sobre nuestras propiedades y servicios. Estamos aquí para ayudarte.";

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content="https://susanaespinoza.com.ar/favicon.ico" />
      </Helmet>
      <VuelveArriba>
        <FormContacto />
      </VuelveArriba>
    </div>
  );
};

export default Contacto;
